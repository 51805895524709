// @font-face {
//   font-family: 'Cerebri Sans';
//   src: url('/fonts/cerebri-sans-light/cerebri-sans-light.eot');
//   src: url('/fonts/cerebri-sans-light/cerebri-sans-light.eot?#iefix') format('embedded-opentype'),
//        url('/fonts/cerebri-sans-light/cerebri-sans-light.woff2') format('woff2'),
//        url('/fonts/cerebri-sans-light/cerebri-sans-light.woff') format('woff'),
//        url('/fonts/cerebri-sans-light/cerebri-sans-light.ttf') format('truetype'),
//        url('/fonts/cerebri-sans-light/cerebri-sans-light.svg#youworkforthem') format('svg');
//   font-weight: 200;
//   font-style: normal;
// }

@font-face {
  font-family: 'Cerebri Sans';
  font-display: swap;
  src: url('/fonts/cerebri-sans-regular/cerebri-sans-regular.eot');
  src: url('/fonts/cerebri-sans-regular/cerebri-sans-regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/cerebri-sans-regular/cerebri-sans-regular.woff2') format('woff2'),
    url('/fonts/cerebri-sans-regular/cerebri-sans-regular.woff') format('woff'),
    url('/fonts/cerebri-sans-regular/cerebri-sans-regular.ttf') format('truetype'),
    url('/fonts/cerebri-sans-regular/cerebri-sans-regular.svg#youworkforthem') format('svg');
  font-weight: 300;
  font-style: normal;
}

// @font-face {
//   font-family: 'Cerebri Sans';
//   font-display: swap;
//   src: url('/fonts/cerebri-sans-book/cerebri-sans-book.eot');
//   src: url('/fonts/cerebri-sans-book/cerebri-sans-book.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/cerebri-sans-book/cerebri-sans-book.woff2') format('woff2'),
//     url('/fonts/cerebri-sans-book/cerebri-sans-book.woff') format('woff'),
//     url('/fonts/cerebri-sans-book/cerebri-sans-book.ttf') format('truetype'),
//     url('/fonts/cerebri-sans-book/cerebri-sans-book.svg#youworkforthem') format('svg');
//   font-weight: 400;
//   font-style: normal;
// }

@font-face {
  font-family: 'Cerebri Sans';
  font-display: swap;
  src: url('/fonts/cerebri-sans-medium/cerebri-sans-medium.eot');
  src: url('/fonts/cerebri-sans-medium/cerebri-sans-medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/cerebri-sans-medium/cerebri-sans-medium.woff2') format('woff2'),
    url('/fonts/cerebri-sans-medium/cerebri-sans-medium.woff') format('woff'),
    url('/fonts/cerebri-sans-medium/cerebri-sans-medium.ttf') format('truetype'),
    url('/fonts/cerebri-sans-medium/cerebri-sans-medium.svg#youworkforthem') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  font-display: swap;
  src: url('/fonts/cerebri-sans-semibold/cerebri-sans-semibold.eot');
  src: url('/fonts/cerebri-sans-semibold/cerebri-sans-semibold.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/cerebri-sans-semibold/cerebri-sans-semibold.woff2') format('woff2'),
    url('/fonts/cerebri-sans-semibold/cerebri-sans-semibold.woff') format('woff'),
    url('/fonts/cerebri-sans-semibold/cerebri-sans-semibold.ttf') format('truetype'),
    url('/fonts/cerebri-sans-semibold/cerebri-sans-semibold.svg#youworkforthem') format('svg');
  font-weight: 600;
  font-style: normal;
}

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-Black.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-Black.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-Black.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-BlackItalic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-BlackItalic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-BlackItalic.ttf') format('truetype');
//   font-weight: 900;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-Bold.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-Bold.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-Bold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-BoldItalic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-BoldItalic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-BoldItalic.ttf') format('truetype');
//   font-weight: 700;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-ExtraBold.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-ExtraBold.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-ExtraBold.ttf') format('truetype');
//   font-weight: 800;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-ExtraBoldItalic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-ExtraBoldItalic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-ExtraBoldItalic.ttf') format('truetype');
//   font-weight: 800;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-Medium.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-Medium.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-MediumItalic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-MediumItalic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-MediumItalic.ttf') format('truetype');
//   font-weight: 500;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-SemiBold.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-SemiBold.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-SemiBold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-SemiBoldItalic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-SemiBoldItalic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-SemiBoldItalic.ttf') format('truetype');
//   font-weight: 600;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-ExtraLight.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-ExtraLight.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-ExtraLight.ttf') format('truetype');
//   font-weight: 200;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-ExtraLightItalic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-ExtraLightItalic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-ExtraLightItalic.ttf') format('truetype');
//   font-weight: 200;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-Light.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-Light.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-LightItalic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-LightItalic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-LightItalic.ttf') format('truetype');
//   font-weight: 300;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-Regular.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-Regular.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-Italic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-Italic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-Italic.ttf') format('truetype');
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-Thin.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-Thin.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-Thin.ttf') format('truetype');
//   font-weight: 100;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Public Sans';
//   font-display: swap;
//   src: url('/fonts/public-sans/PublicSans-ThinItalic.woff2') format('woff2'),
//     url('/fonts/public-sans/PublicSans-ThinItalic.woff') format('woff'),
//     url('/fonts/public-sans/PublicSans-ThinItalic.ttf') format('truetype');
//   font-weight: 100;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'LL Circular';
//   src: url('/fonts/circular-book-italic/lineto-circular-bookItalic.eot');
//   src: url('/fonts/circular-book-italic/lineto-circular-bookItalic.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/circular-book-italic/lineto-circular-bookItalic.woff2') format('woff2'),
//     url('/fonts/circular-book-italic/lineto-circular-bookItalic.woff') format('woff');
//   font-weight: 300;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'LL Circular';
//   src: url('/fonts/circular-book/lineto-circular-book.eot');
//   src: url('/fonts/circular-book/lineto-circular-book.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/circular-book/lineto-circular-book.woff2') format('woff2'),
//     url('/fonts/circular-book/lineto-circular-book.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'LL Circular';
//   src: url('/fonts/circular-medium/lineto-circular-medium.eot');
//   src: url('/fonts/circular-medium/lineto-circular-medium.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/circular-medium/lineto-circular-medium.woff2') format('woff2'),
//     url('/fonts/circular-medium/lineto-circular-medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'LL Circular';
//   src: url('/fonts/circular-medium-italic/lineto-circular-mediumItalic.eot');
//   src: url('/fonts/circular-medium-italic/lineto-circular-mediumItalic.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/circular-medium-italic/lineto-circular-mediumItalic.woff2') format('woff2'),
//     url('/fonts/circular-medium-italic/lineto-circular-mediumItalic.woff') format('woff');
//   font-weight: 500;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'LL Circular';
//   src: url('/fonts/circular-bold/lineto-circular-bold.eot');
//   src: url('/fonts/circular-bold/lineto-circular-bold.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/circular-bold/lineto-circular-bold.woff2') format('woff2'),
//     url('/fonts/circular-bold/lineto-circular-bold.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'LL Circular';
//   src: url('/fonts/circular-black/lineto-circular-black.eot');
//   src: url('/fonts/circular-black/lineto-circular-black.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/circular-black/lineto-circular-black.woff2') format('woff2'),
//     url('/fonts/circular-black/lineto-circular-black.woff') format('woff');
//   font-weight: 900;
//   font-style: normal;
// }
